<template>
  <div style="position: fixed;top: 0;width: 100%;z-index: 10;box-shadow: 0px 10px 9px 1px rgba(79, 114, 230, 0.1);">
    <el-row>
      <el-col :class="isColor ? 'line-color': 'white-color'" :span="24">
        <div :class="routerName === 'enterprise-details' ? 'containers' : 'container'">
          <el-row>
            <el-col class="nav-content" :span="24">
              <el-image style="cursor: pointer;" @click="handleToIndex" fit="contain" :src="isColor ? imgScoure.deep : imgScoure.white"></el-image>
              <div class="tips-class">“国家级中小企业公共服务示范平台”易工程出品</div>
              <index-search-top v-if="routerName === 'enterprise-details'" @loginEmit="loginEmit" class="index-search-content"></index-search-top>
              <div class="dis-sta-cen">
                <el-menu background-color="rgba(255,255,255,0)" :active-text-color="isColor ? '#fff': '#5D6FE9'"
                  :text-color="isColor ? '#fff': '#808080'" :default-active="activeIndex" class="el-menu-demo"
                  mode="horizontal" @select="handleSelect">
                  <el-menu-item :index="item.index" :key="index" v-for="(item, index) of navList">{{item.name}}</el-menu-item>
                  <!-- <el-menu-item @click="handleToData" class="ssss">
                    机构登录
                  </el-menu-item> -->
                  <el-menu-item @click="handleToMes" v-if="isLogin" index="8">
                    <el-badge v-if="mesCont > 0" :value="mesCont" :max="99" class="icon-sup">
                      <span class="icon iconfont">&#xe61f;</span>
                    </el-badge>
                    <span v-else class="icon iconfont">&#xe61f;</span>
                  </el-menu-item>
                  <el-submenu index="2-4" v-if="isLogin">
                    <template #title>{{realName}}</template>
                    <el-menu-item style="color: #000000;" index="2-4-1" @click="handlerPersonCenter">个人中心</el-menu-item>
                    <el-menu-item style="color: #000000;" index="2-4-2" @click="handlerLogout">退出登录</el-menu-item>
                  </el-submenu>
                </el-menu>
                <div v-if="!isLogin">
                  <el-button size="medium" :class="isColor ? '': 'btn-login'" @click="handlerRegister">注册</el-button>
                  <el-button size="medium" :class="isColor ? 'btn-login': ''" @click="loginEmit">登录</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { computed } from 'vue'
import { mapGetters } from 'vuex'
import IndexSearchTop from '@/components/index-search/index-search-top'
import { forwardBID } from '@/api/forward.js'
// import store from '@/store'

export default {
  name: 'topNav',
  // props: ['navColor', 'name', 'msg'],
  props: {
    navColor: String,
    name: String,
    msg: String
  },
  components: { IndexSearchTop },
  data () {
    return {
      isColor: this.navColor,
      isName: this.name,
      routerName: this.name.name,
      realName: '',
      activeIndex: '9',
      imgScoure: {
        white: require('../../assets/index/logo-w.png'),
        deep: require('../../assets/index/logo-s.png'),
        flooter: require('../../assets/index/logo-d.png')
      },
      isLogin: false,
      navList: [
        {
          name: '首页',
          index: '0',
          url: 'index'
        },
        {
          name: '企业报告',
          index: '1',
          url: 'report'
        },
        {
          name: 'VIP会员',
          index: '2',
          url: 'vip-index'
        },
        {
          name: '数据维护',
          index: '3',
          url: 'data-correction'
        }
      ]
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo',
      'mesCont'
    ])
  },
  created () {
    const vm = this
    const name = vm.name.name
    for (let i = 0; i < vm.navList.length; i++) {
      if (vm.navList[i].url === name) {
        vm.activeIndex = vm.navList[i].index
        break
      } else {
        vm.activeIndex = 9
      }
    }
    if (this.$store.getters.idToken) {
      this.isLogin = true
    } else {
      this.isLogin = false
    }
    if (this.$store.getters.userInfo) {
      const user = JSON.parse(this.$store.getters.userInfo)
      this.realName = user.realName ? user.realName : user.mobile
    } else {
      this.realName = ''
    }
  },
  methods: {
    handleToIndex () {
      const vm = this
      vm.$router.push({
        name: 'index'
      })
    },
    handleSelect (key, keyPath) {
      const vm = this
      console.log(key)
      console.log(keyPath)
      if (vm.navList[key]?.url) {
        vm.$router.push({
          name: vm.navList[key].url
        })
      }
      // 等同于 vm.navList[key] && vm.navList[key].url
      // if (vm.navList[key]?.url) {
      //   if (key === '3') { // 数据纠错
      //     if (vm.idToken) {
      //       // 认证过企业 去数据纠错
      //       vm.$router.push({
      //         name: 'data-correction'
      //       })
      //     } else {
      //       // 去登录
      //       vm.loginEmit()
      //     }
      //   } else {
      //     vm.$router.push({
      //       name: vm.navList[key].url
      //     })
      //   }
      // }
    },
    handlerLogout () {
      this.$store.dispatch('logout')
      this.isLogin = false
      this.$router.push({ name: 'index' })
    },
    handlerPersonCenter () {
      this.$router.push({ name: 'personal-center' })
    },
    handlerRegister () {
      this.$router.push({ name: 'Register' })
    },
    getMessage () {
      const data = {
        dateBackgroundUrl: '/bid/noticeUser/getNoticeUserNumToXinYiFu',
        dateBackgroundRequestType: 'get'
      }
      forwardBID(data).then(result => {
        if (result.code === 0) {
          this.$store.dispatch('setMesCont', result.data)
        }
      })
    },
    handleToMes () {
      this.$router.push({ name: 'message-center' })
    },
    handleToData () {
      window.open('https://data.yixinxm.com:8443/backstage/#/login', '_black')
    }
  },
  mounted () {
    const vm = this
    vm.getMessage()
    window.onscroll = function () {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      if (vm.routerName === 'index' || vm.routerName === 'report' || vm.routerName === 'data-correction' || vm.routerName === 'vip-index') {
        if (scrollTop > 130) {
          vm.isColor = false
        } else {
          vm.isColor = true
        }
      }
    }
  },
  watch: {
    isName: {
      handler (val, oldval) {
        console.log(oldval)
      }
    },
    $route: {
      handler (val, oldval) {
        const vm = this
        vm.getMessage()
        vm.isColor = val.meta.navColor
        const name = val.name ? val.name : oldval.name
        vm.routerName = name
        for (let i = 0; i < vm.navList.length; i++) {
          if (vm.navList[i].url === name) {
            vm.activeIndex = vm.navList[i].index
            break
          } else {
            vm.activeIndex = 9
          }
        }
      }
    },
    '$store.getters.idToken': {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue) {
          this.isLogin = true
        } else {
          this.isLogin = false
        }
      }
    },
    '$store.getters.userInfo': {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue) {
          const user = JSON.parse(newValue)
          this.realName = user.realName ? user.realName : user.mobile
        } else {
          this.realName = ''
        }
      }
    }
  },
  setup (props, { emit }) {
    const message = computed(() => {
      return '父组件值为：' + props.msg
    })
    function loginEmit () {
      emit('loginEmit', 'topNav')
    }
    return {
      loginEmit, message
    }
  }
}
</script>

<style lang="less" scoped="scoped">
.white-color {
  background-color: #fff;
  z-index: 2;
  height: 60px;
  min-height: 40px;

  .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-menu-demo {
      height: 60px;
      background-color: none;
      border: none;
      .el-menu.el-menu--horizontal {
        border: none;
      }
    }

    .el-menu-item:hover {
      background-color: rgba(255, 255, 255, 0) !important;
      font-weight: 600;
    }
    .el-menu-item:focus {
      background-color: rgba(255, 255, 255, 0) !important;
    }
    .el-menu--horizontal>.el-menu-item {
      height: 60px;
      line-height: 60px;
      transition: none !important;

      .el-menu-item:hover {
        background-color: rgba(255, 255, 255, 0);
        color: #1787FB !important;
        text-decoration: underline;
      }
    }

    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title {
      height: 60px;
      line-height: 60px;
      transition: none !important;
    }

    /deep/ .el-submenu__title i {
      color: #999;
    }

    .el-menu--horizontal>.el-menu-item.is-active {
      color: #5D6FE9;
      border-bottom: none;
      position: relative;
      font-weight: 600;
    }

    .el-menu--horizontal>.el-menu-item.is-active::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 50%;
      margin-left: calc(-50% + 25%);
      height: 2px;
      width: 50%;
      background-color: #5D6FE9;
    }

    .el-button.btn-login {
      color: #fff;
      background: #5D6FE9;
      border: 1px solid #FFFFFF;
      border-radius: 4px;
    }

    /deep/ .el-badge__content.is-fixed {
      top: 14px;
    }

    /deep/ .el-badge__content--primary {
      background-color: red;
    }

    /deep/ .el-badge__content {
      height: 14px;
      line-height: 14px;
      border: none;
    }
  }
  .link-data {
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    padding: 10px;
    color: rgb(128, 128, 128);
    cursor: pointer;
  }
  .ssss {
    color: rgb(128, 128, 128) !important;
    font-weight: 400 !important;
  }
  .nav-content .el-menu--horizontal > .el-menu-item.is-active.ssss::after{
    height:0 !important;
  }
}

.line-color {
  z-index: 2;
  background: rgba(255, 255, 255, 0.1);
  height: 60px;
  min-height: 40px;

  .nav-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-menu-demo {
      height: 60px;
      background-color: none;
      border: none;

      .el-menu.el-menu--horizontal {
        border: none;
      }
    }

    .el-menu-item:hover {
      background-color: rgba(255, 255, 255, 0) !important;
      font-weight: bold;
    }
    .el-menu-item:focus {
      background-color: rgba(255, 255, 255, 0) !important;
    }
    .el-menu--horizontal>.el-menu-item {
      height: 60px;
      line-height: 60px;
      transition: none !important;
    }

    /deep/ .el-menu--horizontal>.el-submenu .el-submenu__title {
      height: 60px;
      line-height: 60px;
      transition: none !important;
    }

    /deep/ .el-submenu__title i {
      color: #fff;
    }

    .el-menu--horizontal>.el-menu-item.is-active {
      color: #fff;
      border-bottom: none;
      position: relative;
      font-weight: 600;
    }

    .el-menu--horizontal>.el-menu-item.is-active::after {
      content: '';
      position: absolute;
      bottom: 5px;
      left: 50%;
      margin-left: calc(-50% + 25%);
      height: 2px;
      width: 50%;
      background-color: #fff;
    }

    .el-button.btn-login {
      background: rgba(255, 255, 255, 0);
      border: 1px solid #FFFFFF;
      border-radius: 4px;
      color: #fff;
    }

    /deep/ .el-badge__content.is-fixed {
      top: 14px;
    }

    /deep/ .el-badge__content--primary {
      background-color: red;
    }

    /deep/ .el-badge__content {
      height: 14px;
      line-height: 14px;
      border: none;
    }
  }
  .link-data {
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    padding: 10px;
    color: #fff;
    cursor: pointer;
  }
  .ssss {
    color: #fff !important;
    font-weight: 400 !important;
  }
  .nav-content .el-menu--horizontal > .el-menu-item.is-active.ssss::after{
    height:0 !important;
  }
}
.el-menu-item.is-active {
  background-color: rgba(255,255,255,0) !important;
}
/deep/ .el-submenu__title {
  max-width: 180px;
  text-overflow :ellipsis;
  white-space :nowrap;
  overflow : hidden;
}
/deep/ .el-menu--popup {
  min-width: 100px !important;
}
.link-data:hover {
  font-weight: 600;
}
.containers .tips-class {
  background-color: red;
  color: #fff;
  width: 12%;
  text-align: center;
  margin-left: -10px;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}
.container .tips-class {
  background-color: red;
  color: #fff;
  width: 230px;
  text-align: center;
  margin-left: -150px;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}

</style>
