import api from '@/api/index'
// axios
import request from '@/utils/request'

/**
 * 条件查询数据字典
 * @param data
 */
export function getDictByModel (data) {
  return request({
    url: api.GetDictByModel,
    method: 'get',
    params: data,
    hideloading: true
  })
}
