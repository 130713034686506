<template>
  <div style="width: 30%;">
    <el-row class="dis-cen-end">
      <el-col :span="24">
        <div class="dis-sta-cen" style="margin-bottom: 5px;">
          <span class="search-btn" :class="{active:index == btnActive}" v-for="(item, index) of navButtonList" :key="index" @click="handleClick(item, index)">
            {{item.name}}
            <i class="icon-sj"></i>
          </span>
        </div>
        <div style="position: relative;">
          <el-input class="input-search" clearable :placeholder="navButtonList[btnActive].introduce" @focus="handleInputFocus" @blur="handleInputBlur" :input-style="isfocus=='blur' ? 'border-color: #DCDFE6;border-bottom-left-radius:4px;height:30px;line-height: 30px;': 'border-color: #DCDFE6;border-bottom-left-radius:0px;height:30px;line-height: 30px;'" v-model="searchVal">
            <template #append>
              <div class="search-btn-to" @click="handleToSearch('1')">查询</div>
            </template>
          </el-input>
          <div style="z-index: 9999999999999;" v-show="isfocus == 'focus'">
            <div style="z-index: 9999999999;">
              <ul class="search-condition" v-if="!searchVal && historyList.length > 0 && idToken">
                <li v-for="(item, index) of historyList" :key="index" @click="handleChooseVal(item)" class="search-condition-li">{{item}}</li>
                <div v-if="!searchVal && historyList.length > 0" class="btn-del-content">
                  <span class="clear-btn" @click="handleClearHis"><i class="el-icon-delete"></i>清空历史</span>
                </div>
              </ul>
              <ul class="search-condition" v-if="searchVal && searchList.length > 0">
                <li v-for="(item, index) of searchList" :key="index" @click="handleChooseVal(item)" class="search-condition-li">{{item}}</li>
              </ul>
            </div>
            <div v-if="!idToken && !searchVal" class="search-condition" style="padding: 20px 0;">
              <p style="font-size: 13px;margin-bottom: 20px;">登陆可查看历史搜索记录</p>
              <el-button size="small" type="primary" @click="loginEmit" style="background-color: #5D6FE9;">立即登录</el-button>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { forwardBG } from '@/api/forward'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'index-search',
  data () {
    return {
      btnActive: 0,
      isfocus: 'blur',
      // isLogin: this.vipData,
      isLogin: false,
      searchVal: '',
      navButtonList: [
        {
          name: '查企业',
          url: 'company',
          introduce: '请输入企业名称关键词',
          active: '1'
        },
        {
          name: '查资质',
          url: 'company-credential',
          introduce: '请输入资质名称关键词',
          active: '2'
        },
        {
          name: '查中标',
          url: 'company-achievement',
          introduce: '请输入中标项目关键词',
          active: '3'
        },
        {
          name: '查荣誉',
          url: 'company-honor-image',
          introduce: '请输入荣誉证书关键词',
          active: '4'
        },
        {
          name: '查人员',
          url: 'company-registered-personnel',
          introduce: '请输入人员名称关键词',
          active: '5'
        },
        {
          name: '查业绩',
          url: 'company-personnel-perfo',
          introduce: '请输入企业名称关键词',
          active: '6'
        }
        // {
        //   name: '知识产权',
        //   url: 'company-intellectual-property',
        //   introduce: '',
        //   active: '7'
        // }
      ],
      historyList: [],
      searchList: []
    }
  },
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  watch: {
    searchVal (newVal) {
      const vm = this
      const thisSearchVal = newVal.trim()
      vm.searchList = []
      if (thisSearchVal && vm.btnActive === 0) {
        // 查询公司模糊
        const data = {
          data: {
            companyInfo: [{ companyName: thisSearchVal }],
            pageSize: 10
          },
          dateBackgroundRequestType: 'post',
          dateBackgroundUrl: '/esComplexInfo/getESCompanyInfoListByCompanyKeyword'
        }
        forwardBG(data).then(result => {
          if (result.code === 0) {
            const thisSearchList = []
            const data = result.data
            if (data.length > 0) {
              for (let i = 0; i < data.length; i++) {
                thisSearchList.push(data[i].companyName)
                if (i >= 5) break
              }
              vm.searchList = thisSearchList
            } else {
              vm.getSkyErr(thisSearchVal)
            }
          }
        }).catch(error => {
          console.log(error)
        })
      } else if (thisSearchVal && vm.btnActive === 1) {
        // 查询资质模糊
        const data = {
          data: { credentialName: thisSearchVal },
          dateBackgroundRequestType: 'post',
          dateBackgroundUrl: '/esComplexInfo/getCredentialListByKeyword'
        }
        forwardBG(data).then(result => {
          if (result.code === 0) {
            const thisSearchList = []
            const data = result.data ? result.data : []
            for (let i = 0; i < data.length; i++) {
              thisSearchList.push(data[i].qualificationName)
              if (i >= 5) break
            }
            vm.searchList = thisSearchList
          }
        }).catch(error => {
          console.log(error)
        })
      } else if (thisSearchVal && vm.btnActive === 1) {
        // 查询资质模糊
        const data = {
          data: { credentialName: thisSearchVal },
          dateBackgroundRequestType: 'post',
          dateBackgroundUrl: '/esComplexInfo/getCredentialListByKeyword'
        }
        forwardBG(data).then(result => {
          if (result.code === 0) {
            const thisSearchList = []
            const data = result.data ? result.data : []
            for (let i = 0; i < data.length; i++) {
              thisSearchList.push(data[i].qualificationName)
              if (i >= 5) break
            }
            vm.searchList = thisSearchList
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        vm.searchList = []
      }
    }
  },
  mounted () {
  },
  methods: {
    getSkyErr (name) {
      const vm = this
      const data = {
        data: {
          keyword: name
        },
        dateBackgroundRequestType: 'get',
        dateBackgroundUrl: '/esComplexInfo/getCompanyByKeyword'
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const thisSearchList = []
          const data = result.data
          for (let i = 0; i < data.length; i++) {
            thisSearchList.push(data[i].companyName)
            if (i >= 5) break
          }
          vm.searchList = thisSearchList
        }
      }).catch(error => {
        console.log(error)
      })
    },
    handleClick: function (item, e) {
      const vm = this
      vm.searchList = []
      vm.historyList = []
      vm.btnActive = e
      if (e === 6) {
        vm.$router.push({
          name: vm.navButtonList[vm.btnActive].url
        })
      }
    },
    handleChooseVal: function (e) {
      console.log(e)
      this.searchVal = e
    },
    handleInputFocus: function (e) {
      const vm = this
      vm.isfocus = e.type
      const localStorageKey = vm.getHistoryListKey()
      if (localStorage.getItem(localStorageKey)) {
        vm.historyList = JSON.parse(localStorage.getItem(localStorageKey))
      }
    },
    handleInputBlur: function (event) {
      const vm = this
      setTimeout(function () {
        vm.isfocus = event.type
      }, 200)
    },
    handleToSearch: function (e) {
      const vm = this
      if (e === '1') {
        vm.setStorage()
        vm.$router.push({
          name: vm.navButtonList[vm.btnActive].url,
          query: {
            searchVal: vm.searchVal
          }
        })
      } else {
        vm.$router.push({
          name: 'high-query-conditions'
        })
      }
    },
    handleClearHis: function () {
      const vm = this
      const arr = []
      const localStorageKey = vm.getHistoryListKey()
      localStorage.setItem(localStorageKey, arr)
      this.historyList = []
    },
    setStorage: function () {
      const vm = this
      let indexSearchList = []
      const localStorageKey = vm.getHistoryListKey()
      if (localStorage.getItem(localStorageKey)) {
        indexSearchList = JSON.parse(localStorage.getItem(localStorageKey))
      }
      if (JSON.stringify(indexSearchList).indexOf(vm.searchVal) === -1) {
        indexSearchList.unshift(vm.searchVal)
        // 限制长度
        if (indexSearchList.length > 5) indexSearchList = indexSearchList.slice(0, 5)
        localStorage.setItem(localStorageKey, JSON.stringify(indexSearchList))
      }
    },
    getHistoryListKey () {
      const vm = this
      let localStorageKey = ''
      if (vm.btnActive === 0) localStorageKey = 'index-search-company'
      if (vm.btnActive === 1) localStorageKey = 'index-search-credential'
      if (vm.btnActive === 2) localStorageKey = 'index-search-achievement'
      if (vm.btnActive === 3) localStorageKey = 'index-search-honor-image'
      if (vm.btnActive === 4) localStorageKey = 'index-search-registered-personnel'
      if (vm.btnActive === 5) localStorageKey = 'index-search-personnel-perfo'
      if (vm.btnActive === 6) localStorageKey = 'index-search-intellectual-property'
      return localStorageKey
    }
  }
}
</script>

<style scoped="scoped" lang="less">
/deep/.el-input--suffix .el-input__suffix {
  margin-top: -5px;
}
.input-search {
  height: 30px;
  line-height: 30px;
}
.search-btn {
  margin-right: 20px;
  color: #5C6EE9;
  font-size: 12px;
  border-radius: 4px;
  cursor: pointer;
}
.search-btn:hover {
  font-weight: 600;
}
.search-btn.active {
  background-color: #5C6EE9;
  color: #fff;
  padding: 1px 5px;
  position: relative;
  .icon-sj {
    position: absolute;
    bottom: -5px;
    margin-left: -5px;
    left: 50%;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
  }
}
/deep/ .el-input-group__append, .el-input-group__prepend {
  background-color: #FC7A1E;
  border: none;
  color: #fff;
  width: 80px;
  padding: 0;
}
.senior-btn {
  width: 100px;
  height: 46px;
  margin-left: 20px;
  vertical-align: text-bottom;
  border: 2px solid #FC7A1E;
  border-radius: 4px;
  line-height: 46px;
  display: block;
  color: #fff;
  cursor: pointer;
}
.search-btn-to {
  display: block;
  width: 100%;
  font-size: 16px;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
}
.search-condition {
  position: absolute;
  z-index: 9999999 !important;
  top: 30px;
  left: 0;
  background-color: #fff;
  width: calc(100% - 82px);
  border: 1px solid #eee;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  .search-condition-li {
    cursor: pointer;
    list-style: none;
    padding: 5px 10px;
    height: 20px;
    line-height: 20px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
  }
  .search-condition-li:hover{
    background-color: #F3F9FD;
    color: #128bed;
  }
  .btn-del-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 15px;
    font-size: 12px;
    color: #666;
    .clear-btn {
      cursor: pointer;
    }
  }
}
</style>
